import React, { useEffect, useMemo, useState, useCallback } from "react"
import { Flex, Text } from "@theme-ui/components"

import Medusa from "../../../services/api"
import { countryLookup, isoData } from "../../../utils/countries"
import { sortByName } from "../../../utils/sortings"
import InputField from "../input-field"
import Select, { Option } from "../select"

export const CountryPicker = ({
  countryCode,
  onClick,
  onChange,
  showLabel = true,
  handleCountryChange,
  sx,
}) => {
  const [countries, setCountries] = useState([])
  const [country, setCountry] = useState()
  const [countrySearchInput, setCountrySearch] = useState("")

  const loadCountries = useCallback(async () => {
    Medusa.regions
      .list()
      .then(({ data }) => {
        let countries = data.regions.map((region) => {
          return region.countries.map((country) => {
            const countryDisplayName = countryLookup(
              country.iso_2.toUpperCase()
            )

            return {
              value: country.iso_2.toUpperCase(),
              label: countryDisplayName,
            }
          })
        })
        const result = countries
          .flat()
          .sort((a, b) => sortByName(a.label, b.label))
        return result
      })
      .then((countries) => {
        setCountries(countries)
      })
  }, [])

  useEffect(() => {
    loadCountries().catch((err) => console.error(err))
  }, [loadCountries])

  useEffect(() => {
    if (countryCode) {
      const currentCountry = isoData.find(
        (o) =>
          o.alpha2.toLowerCase() === countryCode.toLowerCase().toLowerCase()
      )

      if (currentCountry) {
        setCountry({ label: currentCountry.name, value: currentCountry.alpha2 })
        setCountrySearch(currentCountry.name)
      }
    }
  }, [countryCode])

  const allCountriesMap = useMemo(() => {
    return countries?.map((option) => (
      <Option
        key={option.label}
        option={option}
        className="option"
        onClick={() => {
          if (
            countryCode &&
            option?.value?.toLowerCase() === countryCode?.toLowerCase()
          )
            return
          onClick(option)
          setCountrySearch(null)
        }}
      />
    ))
  }, [countries])

  const searchActive =
    countrySearchInput &&
    countrySearchInput !== country.label &&
    countrySearchInput !== ""

  const handleSearch = () => {
    if (!countrySearchInput || countrySearchInput.trim() === "") {
      allCountriesMap
    } else
      return countries
        .filter((option) =>
          option.label
            .toLowerCase()
            .startsWith(countrySearchInput.toLowerCase())
        )
        .map((option) => (
          <Option
            key={option.label}
            option={option}
            className="option"
            onClick={() => {
              if (
                countryCode &&
                option?.value?.toLowerCase() === countryCode?.toLowerCase()
              )
                return
              onClick(option)
              setCountrySearch(null)
            }}
          />
        ))
  }

  return (
    <Flex sx={{ flexDirection: "column", ...sx }}>
      <Select
        id="countryCode"
        name="countryCode"
        className="select"
        type="text"
        label={"Country code"}
        placeholder={"Country code"}
        value={country?.value ?? ""}
        onClickOutside={() => setCountrySearch(null)}
        valueRender={() => (
          <Flex sx={{ flexDirection: "column", flex: 1 }}>
            {showLabel && (
              <Text sx={{ fontSize: "13px", color: "grayscale.600" }}>
                Country/Area *
              </Text>
            )}
            <InputField
              value={countrySearchInput ?? country?.label ?? ""}
              onClick={() => setCountrySearch("")}
              onChange={(e) => {
                setCountrySearch(e.target.value)
              }}
              inputSx={{
                backgroundColor: "transparent",
                border: "none",
                paddingLeft: "0px",
                padding: 0,
                margin: 0,
                height: "initial",
              }}
              sx={{
                width: "100%",
                fontSize: "15px",
                color: "primary",
                input: { height: "auto !important" },
              }}
            />
          </Flex>
        )}
        options={countries}
        allOptionsRender={searchActive ? handleSearch : null}
        onOptionClick={(option) => {
          if (
            countryCode &&
            option?.value?.toLowerCase() === countryCode?.toLowerCase()
          )
            return
          handleCountryChange(option.value.toLowerCase())
          onClick(option)
          onChange && onChange()
        }}
        sx={{ background: "grayscale.white", height: "50px" }}
      />
    </Flex>
  )
}
