import React, { useRef } from "react"
import styled from "@emotion/styled"
import { Flex, Text, Label } from "theme-ui"
import { useFormik } from "formik"
import ReactPhoneInput from "react-phone-input-2"
import * as Yup from "yup"

import Layout from "../../layouts"
import AccountLayout from "../../layouts/account-layout"
import { useAccountContext } from "../../../context/AccountContext"
import InputField from "../../new-ui/input-field"
import Button from "../../new-ui/button"
import { useNotificationContext } from "../../../context/NotificationContext"
import { isoData } from "../../../utils/countries"
import useWindowSize from "../../../hooks/useWindowSize"
import { trackAccountInformationChanged } from "../../../services/analytics"
import { CountryPicker } from "../../new-ui/country-picker"

const Form = styled.form`
  > div {
    margin-bottom: 24px;
  }
`

const AccountInformation = () => {
  const {
    firstName,
    lastName,
    savedAddresses,
    metadata,
    updatePassword,
    updateDetails,
    pushAddress,
    billingAddressId,
    updateAddress,
  } = useAccountContext()

  const { width } = useWindowSize()

  return (
    <Layout navbarTransparent={width > 768}>
      <AccountLayout>
        <Flex
          sx={{
            "> div": { flexBasis: "100%" },
            flexWrap: "wrap",
            flex: 1,
          }}
        >
          <Flex sx={{ flexDirection: "column", borderBottom: "grey" }}>
            <Text
              as="h2"
              sx={{
                marginTop: 0,
                fontSize: "21px",
                marginBottom: "32px",
                fontWeight: "normal",
              }}
            >
              Account information
            </Text>
            {firstName && (
              <AccountInformationForm
                firstName={firstName}
                lastName={lastName}
                metadata={metadata}
                updateDetails={updateDetails}
              />
            )}
          </Flex>

          <Flex sx={{ flexDirection: "column", borderBottom: "grey" }}>
            <Text
              as="h2"
              sx={{
                fontSize: "21px",
                marginBlock: "32px",
                fontWeight: "normal",
              }}
            >
              Shipping information
            </Text>
            {savedAddresses[0] ? (
              <AddressForm
                key={savedAddresses[0].address_1}
                address={savedAddresses[0]}
                updateAddress={updateAddress}
                userInformation={{
                  first_name: firstName,
                  last_name: lastName,
                }}
                billingAddressId={billingAddressId}
              />
            ) : (
              <AddressForm
                key={"new-address"}
                address={{
                  id: undefined,
                  address_1: undefined,
                  city: undefined,
                  postal_code: undefined,
                  country: undefined,
                  country_code: undefined,
                  phone: undefined,
                  placeholder: true,
                }}
                updateAddress={updateAddress}
                pushAddress={pushAddress}
                userInformation={{
                  first_name: firstName,
                  last_name: lastName,
                }}
                billingAddressId={billingAddressId}
              />
            )}
          </Flex>
          <Flex sx={{ flexDirection: "column", borderBottom: "grey" }}>
            <Text
              as="h2"
              sx={{
                fontSize: "21px",
                marginBlock: "32px",
                fontWeight: "normal",
              }}
            >
              Password
            </Text>
            <PasswordForm updatePassword={updatePassword} />
          </Flex>
        </Flex>
      </AccountLayout>
    </Layout>
  )
}

export default AccountInformation

const AccountInformationForm = ({
  firstName,
  lastName,
  metadata,
  updateDetails,
}) => {
  const AccountInformationScheme = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email(),
  })

  const { pushNotification } = useNotificationContext()

  const formik = useFormik({
    initialValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      birthday: metadata?.birthday || "",
    },
    validationSchema: AccountInformationScheme,
    validateOnBlur: true,
    onSubmit: async (values) => {
      updateDetails({
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        metadata: { ...metadata, birthday: values.birthday.trim() },
      })
        .then(() => {
          pushNotification({
            id: "update-details",
            body: "Success",
            dismiss: {
              duration: 3000,
            },
          })

          trackAccountInformationChanged(values)
        })
        .catch(() =>
          pushNotification({
            id: "update-details",
            body: "Sorry, something went wrong. Make sure the input is correct and try again",
            dismiss: {
              duration: 3000,
            },
          })
        )
    },
  })

  return (
    <Form onSubmit={formik.handleSubit}>
      <InputField
        id="firstName"
        name="firstName"
        type="text"
        value={formik.values.firstName}
        label="First name"
        placeholder="First name"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.firstName && formik.errors.firstName}
      />
      <InputField
        id="lastName"
        name="lastName"
        type="text"
        value={formik.values.lastName}
        label="Last name"
        placeholder="Last name"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.lastName && formik.errors.lastName}
      />
      <InputField
        id="birthday"
        name="birthday"
        type="date"
        label="Birthday"
        placeholder={"DD/MM/YYYY"}
        value={formik.values.birthday}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.birthday && formik.errors.birthday}
      />
      <Flex sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={formik.handleSubmit}
          sx={{ width: ["100%", "120px"] }}
          disabled={!formik.dirty}
        >
          Save
        </Button>
      </Flex>
    </Form>
  )
}

const PasswordForm = ({ updatePassword }) => {
  const PasswordScheme = Yup.object().shape({
    newPassword: Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must match"
    ),
  })

  const { pushNotification } = useNotificationContext()

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: PasswordScheme,
    validateOnBlur: true,
    onSubmit: async (values) => {
      updatePassword(values.newPassword)
        .then(() =>
          pushNotification({
            id: "new-password",
            body: "Success",
            dismiss: {
              duration: 3000,
            },
          })
        )
        .catch(() =>
          pushNotification({
            id: "new-password",
            body: "Sorry, something went wrong. Make sure the input is correct and try again",
            dismiss: {
              duration: 3000,
            },
          })
        )
    },
  })
  return (
    <Form onSubmit={formik.handleSubit}>
      <InputField
        id="newPassword"
        name="newPassword"
        type="password"
        value={formik.values.newPassword}
        label="New password"
        placeholder="New password"
        allowPeek={true}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && formik.errors.newPassword}
      />
      <InputField
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label="Confirm password"
        placeholder="Confirm password"
        value={formik.values.confirmPassword}
        allowPeek={true}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && formik.errors.confirmPassword}
      />
      <Flex sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={formik.handleSubmit}
          disabled={formik.values.newPassword !== formik.values.confirmPassword}
          sx={{ width: ["100%", "120px"] }}
        >
          Save
        </Button>
      </Flex>
    </Form>
  )
}

const AddressForm = ({
  address,
  updateAddress,
  pushAddress,
  userInformation,
}) => {
  const { id, address_1, city, postal_code, country_code, phone } = address
  const { pushNotification } = useNotificationContext()
  const formik = useRef()

  const currentCountry = isoData.find(
    (o) => o.alpha2.toLowerCase() === country_code
  )

  const AddressFormScheme = Yup.object().shape({
    postalCode: Yup.string().required(),
    countryCode: Yup.object().required(),
    phone: Yup.string().required(),
    address1: Yup.string().required(),
    city: Yup.string().required(),
  })

  formik.current = useFormik({
    initialValues: {
      address1: address_1 || "",
      city: city || "",
      postalCode: postal_code || "",
      countryCode: currentCountry
        ? { value: country_code, label: currentCountry.name }
        : {},
      phone: phone || "",
    },
    validationSchema: AddressFormScheme,
    validateOnBlur: true,

    onSubmit: async (values) => {
      const formData = {
        ...userInformation,
        address_1: values.address1,
        city: values.city,
        postal_code: values.postalCode,
        country_code: values.countryCode.value,
        phone: values.phone,
      }

      try {
        if (pushAddress) {
          await pushAddress(formData)
        } else {
          await updateAddress(id, formData)
        }

        if (formik.current) {
          formik.current.resetForm({
            values,
            touched: {},
          })
        }

        pushNotification({
          id: "added_address",
          body: "Success",
          dismiss: {
            duration: 3000,
          },
        })
      } catch (error) {
        pushNotification({
          id: "added_address",
          body: "Sorry, something went wrong. Make sure the input is correct and try again",
          dismiss: {
            duration: 3000,
          },
        })
      }
    },
  })

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        formik.current.handleSubmit()
      }}
    >
      <InputField
        id="address1"
        name="address1"
        type="text"
        value={formik.current.values.address1}
        label="Address 1"
        placeholder="Address 1"
        onBlur={formik.current.handleBlur}
        onChange={formik.current.handleChange}
        error={
          formik.current.touched.address1 && formik.current.errors.address1
        }
      />
      <InputField
        id="postalCode"
        name="postalCode"
        type="text"
        value={formik.current.values.postalCode}
        label="Postal code"
        placeholder="Postal code"
        onBlur={formik.current.handleBlur}
        onChange={formik.current.handleChange}
        error={
          formik.current.touched.postalCode && formik.current.errors.postalCode
        }
      />
      <InputField
        id="city"
        name="city"
        type="text"
        label="City"
        placeholder="City"
        value={formik.current.values.city}
        onBlur={formik.current.handleBlur}
        onChange={formik.current.handleChange}
        error={formik.current.touched.city && formik.current.errors.city}
      />
      <Flex
        sx={{
          flexDirection: "column",
          flex: 1,
          ".select-value.select-value--error": {
            ...(formik.current.touched.countryCode &&
            !formik.current.values.countryCode.value
              ? {
                  color: (theme) => theme.colors.red,
                  border: (theme) => theme.borders.danger,
                }
              : undefined),
          },
        }}
      >
        <Text>Country</Text>
        <CountryPicker
          countryCode={formik?.current?.values?.countryCode?.value}
          showLabel={false}
          onClick={(option) => {
            formik.current.setFieldValue("countryCode", option)
            formik.current.setFieldValue("phone", "")
          }}
          handleCountryChange={() =>
            (formik.current.touched.countryCode = false)
          }
        />
        {Boolean(
          formik.current.touched.countryCode &&
            !formik.current.values.countryCode.value
        ) && (
          <Label sx={(theme) => ({ color: theme.colors.red })}>
            country is a required field
          </Label>
        )}
      </Flex>
      <Flex
        sx={{
          flexDirection: "column",
          flex: 1,
          ".phone-container": {
            display: "flex",
            flex: 1,
            input: {
              height: "40px",
              padding: "8px",
              flex: 1,
              ...(formik.current.touched.phone && formik.current.errors.phone
                ? {
                    color: (theme) => theme.colors.red,
                    border: (theme) => theme.borders.danger,
                  }
                : undefined),
            },
          },
        }}
      >
        <Text sx={{ paddingBottom: "8px" }}>Phone</Text>
        <ReactPhoneInput
          containerClass="phone-container"
          id="phone"
          enableLongNumbers
          specialLabel=""
          country={formik.current.values.countryCode?.value?.toLowerCase()}
          value={formik.current.values.phone}
          onChange={(phone) =>
            formik.current.setFieldValue("phone", "+" + phone)
          }
        />
        {Boolean(
          formik.current.touched.phone && formik.current.errors.phone
        ) && (
          <Label sx={(theme) => ({ color: theme.colors.red })}>
            {formik.current.errors.phone}
          </Label>
        )}
      </Flex>
      <Flex
        sx={{
          flexDirection: ["column", "row"],
          justifyContent: "flex-end",
        }}
      >
        <Flex>
          <Button
            sx={{ width: ["100%", "120px"] }}
            type="submit"
            disabled={!formik.current.dirty}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Form>
  )
}
